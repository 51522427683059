import type { Space } from '@cocoonspace/sdk-js/types/space'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'

export const TransportationLine = ({
	space,
	stationName,
	line,
	iconSize = 20,
}: {
	space: Pick<Space, 'name' | 'zone'>
	stationName: string
	line: {
		type: string
		lineRefs: string[]
	}
	iconSize?: number
}) => {
	const { t } = useTranslation()

	return (
		<div className='flex flex-row gap-x-0.5'>
			{line.type && (
				<Image
					src={`/images/v2/stations/${space.zone}/${line.type}/${line.type}.svg`}
					alt={`${line.type} ${space.zone}`}
					height={iconSize}
					width={iconSize}
				/>
			)}

			{line.lineRefs
				.filter((lineRef) => lineRef)
				.map((lineRef, index) => (
					<Image
						key={`${line.type}-${lineRef}-${index}`}
						src={`/images/v2/stations/${space.zone}/${line.type}/${lineRef}.svg`}
						alt={t('space:sections.location.station_icon_alt', {
							spaceName: space.name,
							lineRef,
							stationName,
						})}
						height={iconSize}
						width={iconSize}
					/>
				))}
		</div>
	)
}
