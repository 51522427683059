import type { Space } from '@cocoonspace/sdk-js/types/space'
import { capitalize } from '@cocoonspace/shared/utils/capitalize'
import { useTranslation } from 'next-i18next'
import { Price } from '~/components/price'

export const StandardPrice = ({ space }: { space: Pick<Space, 'prices'> }) => {
	const { t } = useTranslation()

	return (
		<div
			data-testid='standard-price'
			className='relative flex flex-col items-end gap-y-1'
		>
			<span className='whitespace-nowrap text-xs'>
				{capitalize(t('common:from'))}
			</span>

			<Price
				suffix='hourlyLong'
				price={space.prices?.weekday_min?.hour_standard}
				className='leading-none'
				suffixClassName='mb-px text-xs'
			/>

			<Price
				suffix='dailyLong'
				price={space.prices?.weekday_min?.day_standard}
				className='leading-none'
				suffixClassName='mb-px text-xs'
			/>
		</div>
	)
}
