import type { Space } from '@cocoonspace/sdk-js/types/space'
import { useMemo } from 'react'

export const useSpaceStations = (space: Pick<Space, 'nearest_stations'>) => {
	const stations = useMemo(() => {
		return space.nearest_stations?.map((station) => {
			const lines = station?.lines?.reduce(
				(acc, code) => {
					const [type] = code.split('-')
					const typeIndex = acc.findIndex((item) => item.type === type)

					if (typeIndex === -1) {
						acc.push({
							type,
							lineRefs: [code],
						})
					} else {
						acc[typeIndex].lineRefs.push(code)
					}

					return acc
				},
				[] as { type: string; lineRefs: string[] }[],
			)

			return {
				...station,
				lines,
			}
		})
	}, [space])

	return { stations }
}
